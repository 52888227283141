import gsap from "gsap/all";
import React, { useEffect, useRef, useState } from "react";

const colors = [
  "#ffffff", //white
  "#37FA8F", //green
  "#F8532B", //orange
  "#9A44EE", //purple
  "#30D4F6", //blue
  "#FFE604", //yellow
];
export default function RankLine({ my_rank, state }) {
  const [isLoading, setIsLoading] = useState(true);
  const load = state.getState().isPageLoading;
  const [activeStep, setActiveStep] = useState(0);

  const { totalLevelsInRank, currentLevel, levelProgress } = my_rank;
  const prRef = useRef(null);
  const stepsRef = useRef(null);

  useEffect(() => {
    !load && setIsLoading(false);
    return () => {};
  }, [load]);

  // line animation
  useEffect(() => {
    if (isLoading) return;
    const el = prRef.current;
    const propgress = (activeStep + 1) / colors.length;

    const width = propgress > 0.33 ? 0.33 * (1 + propgress * 0.1) : propgress;

    // console.log("propgress", propgress, width);

    const tl = gsap.timeline({
      onStart: () => el.classList.add("play"),
      onComplete: () => el.classList.remove("play"),
    });

    tl.to([el], {
      backgroundColor: colors[activeStep],
      width: width * 100 + "%",
      duration: 2.5,
      ...{ ...(propgress == 1 ? { borderRadius: "4px" } : {}) },
      ease: "none",
    });
    return () => {
      tl.kill();
    };
  }, [activeStep, isLoading]); // need levelProgress

  // steps animation
  useEffect(() => {
    if (isLoading) return;
    const steps = [...stepsRef.current.children];
    const step = steps[activeStep];

    const tl = gsap.timeline({
      onStart() {},
      onComplete() {
        setTimeout(() => {
          const next = activeStep + 1;
          next < colors.length && setActiveStep(next);
        }, 1000);
      },
    });

    step.classList.add("active");
    tl.to(
      step,
      {
        backgroundColor: "white",
        boxShadow: "0px 0px 8px 2px #ffffff80",
        duration: 0.5,
        repeat: 4,
        yoyo: true,
      },
      "puls"
    );
    tl.to(
      step,
      {
        boxShadow: "0px 0px 0px 0px #ffffff80",
        duration: 0.5,
      },
      "end"
    );

    return () => {
      tl.revert().kill();
    };
  }, [activeStep, isLoading]);

  return (
    <div className="progress df fd-c w-full my_line_progress">
      <div className="steps df w-full" ref={stepsRef}>
        {/* {Array(totalLevelsInRank)
          .fill()
          .map((l, i) => (
            <span key={i} data-active={i + 1 <= currentLevel}></span>
          ))} */}

        {colors.map((_, id) => (
          <span key={id} className={id < activeStep ? "done" : ""}></span>
        ))}
      </div>
      <div className="line w-full">
        <div className="l" ref={prRef}></div>
      </div>
    </div>
  );
}
