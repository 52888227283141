import React, { useEffect, useState } from "react";
import Input from "/components/core/labels/Input";

import CustomButton from "/components/core/ButtonDefault";
import SelectCurrency from "../SelectCurrency";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import currencies from "../data/fiat-currencies.json";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { BigDecimal, formatInfo } from "../money/index";
import { Receive } from "./receive/index";
import { ProvidersControlled } from "./ProvidersControlled";
import {
  getValidProviders,
  makeCurrency,
  materializeCurrency,
  validate,
} from "./Currency";

window["fiat-currencies"] = currencies; //TODO

const counts = [10, 25, 50, 100];

const labelPlay = {
  placeholder: "Enter Amount",
  name: "amount",
  type: "number",
  autoComplete: "transaction-amount",
  required: true,
  tip: { title: "I want to pay" },
};

const labelBonus = {
  placeholder: "Enter Bonus Code",
  name: "bonusCode",
  autoComplete: "false",
  required: true,
  tip: { title: "Bonus Code (Optional)" },
};

/**
 * Error is ok, min/max can be shapeless
 * @type {import("./types.js").Currency}
 */
const defaultCurrency = makeCurrency({ code: "USD", providers: [] });

export default function Fiat(props) {
  const { back, onClose, state } = props;
  const [amount, setAmount] = useState("");
  const [bonusCode, setBonusCode] = useState("");
  const [isProviders, setIsProviders] = useState(false);
  const axiosClient = useAxiosWithAuth();

  // 1. Get supported fiat currencies with min max range.
  // Cashier act as provider and provide the min/max range, that acts as higher priority range.
  const [currency, setCurrency] = useState(defaultCurrency);
  const validProviders = getValidProviders({ amount, currency });

  /**
   * Supported currencies and min/max range manually set by the provider, and SHOULD be cached.
   * @type {import("swr").SWRResponse<import("./types.js").Currency[]>}
   */
  const availableCurrencies = useSWRImmutable(
    ["cashier/fiat/available-currencies"],
    ([url]) => axiosClient.get(url).then((res) => res.data),
    {
      onSuccess: (data) => {
        if (currency === defaultCurrency && data) {
          console.log("onSuccess setCurrency", currency, defaultCurrency);
          // if it's default then set the real currency instead
          setCurrency(materializeCurrency(data, currency.code));
        }
      },
    }
  );
  /**
   * Having the Min/max range for provider.
   * Call any of (rate and best-rate) endpoints must be made .
   * @type {import("swr").SWRResponse<import("./types.js").BestRate>}
   */
  const bestRate = useSWR(
    () =>
      validProviders.length === 0
        ? null
        : [
            "cashier/fiat/estimated-exchange-result",
            currency.code,
            amount,
            validProviders.map(({ provider }) => provider),
          ],
    ([url, currency, amount, providers]) =>
      axiosClient
        .get(url, {
          params: {
            fromCurrencyAlpha3: currency,
            fromCurrencyAmount: amount,
            providers,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      refreshInterval:
        isProviders && validProviders.length !== 0 ? undefined : 1000,
    }
  );
  const [amountError, setAmountError] = useState(null);

  const goToProviders = () => {
    setIsProviders(true);
  };
  useEffect(() => {
    validateAmount();
    // Show error if have min/max
    function validateAmount() {
      const err = validate({ amount, currency });
      if (err) {
        setAmountError(err);
      } else if (
        !bestRate.isValidating &&
        !bestRate.isLoading &&
        bestRate.error
      ) {
        // todo: show real error, not axios error
        setAmountError(bestRate.error.message || "Try, another amount");
      } else {
        setAmountError();
      }
    }
  }, [
    amount,
    currency,
    bestRate.error,
    bestRate.isValidating,
    bestRate.isLoading,
  ]);

  if (isProviders && validProviders.length !== 0) {
    return (
      <ProvidersControlled
        data={{
          validProviders,
          currency,
          amount,
        }}
        selected={bestRate.data}
        onPay={({ quote_id }) => {
          //close Wallet popup
          window.quote_id = quote_id;
          onClose();
          state.setSimplexWidget(true);

          // show popup on Success
          // state.setPopUp("confirm", {
          //   title: "Purchase Completed",
          //   subtitle:
          //     "Your transfer has been received! <br> <br> You will be notified as soon as the transaction has been successfully confirmed!",
          //   close: "Let’s Play!",
          // });
        }}
        back={() => setIsProviders(false)}
      />
    );
  }

  return (
    <>
      <div className="buy-fiat df fd-c">
        <div className="row-amount df ai-st">
          <Input
            {...labelPlay}
            // min="0.00"
            // max="10000.00"
            // step="0.01"
            value={amount}
            error={amountError}
            handleChange={(e) => {
              if (e.target.value !== amount) {
                setAmount(e.target.value);
              }
            }}
          />
          <SelectCurrency
            disabled={availableCurrencies.isLoading}
            currencys={(availableCurrencies.data ?? [])
              .map((c) => c.code)
              .sort()}
            value={currency.code}
            onChange={(x) => {
              console.log("setCurrency", x);
              setCurrency(materializeCurrency(availableCurrencies.data, x));
            }}
            state={props.state}
          />
        </div>

        <div className="row-counts df w-full fixel_16_sbl">
          {counts.map((count) => (
            <div className="count df ai-c br-14" key={count}>
              <button
                tabIndex={-1}
                disabled={!amount || new BigDecimal(amount).lt(count)}
                className="cta mm ar-1 icon-24"
                onClick={(e) =>
                  setAmount((e) =>
                    new BigDecimal(e).sub(count).toFixed(currency.decimals)
                  )
                }
                title={`sub ${count} from amount`}
              />
              <p>{count}</p>
              <button
                tabIndex={-1}
                className="cta pp ar-1 icon-24"
                onClick={(e) =>
                  setAmount((e) =>
                    new BigDecimal(e ?? "0")
                      .add(count)
                      .toFixed(currency.decimals)
                  )
                }
                title={`add ${count} to amount`}
              />
            </div>
          ))}
        </div>
        <Receive
          isLoading={bestRate.isLoading}
          data={bestRate.data}
          currency={currency}
        />
      </div>

      <Input
        {...labelBonus}
        value={bonusCode}
        handleChange={(e) => setBonusCode(e.target.value)}
      />

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={back}
          classname={`go-back grey`}
          def={40}
          title={"Cancel"}
        />
        <CustomButton
          onClick={goToProviders}
          classname={`go-buy pr`}
          def={40}
          disabled={!amount || amountError || validProviders.length === 0}
          title={"Buy"}
        />
      </div>
    </>
  );
}
