import React, { useContext, useEffect, useRef, useState } from "react";
import FunticoLogo from "/components/core/Logo";
import DelayLink from "/components/core/DelayLink";
import AuthContext from "/context/AuthProvider";
import UnLoginedButton from "/components/core/HeaderNavButtons";
import { startScroll, stopScroll } from "/utils/scroll-config";
import { useWindowScroll } from "/utils/hooks/use-EventListener";
import RecentlyGames from "/components/core/RecentlyGames";
import { featureFlags } from "/context/FeatureFlagsProvider";

const discover = [
  {
    to: "/community-leaderboard",
    title: "Community </br> Leaderboard",
  },
  {
    to: "https://about.funtico.com/",
    title: "About Us",
    target: "_blank",
  },
  {
    to: "https://discord.com/invite/kN4kAAHvQd",
    title: "Join Community",
    target: "_blank",
  },
  {
    to: "/faq",
    title: "FAQ",
  },
];

export default function Aside({ isMobile, state, openNav, isOpen }) {
  const [active, setActive] = useState(false);
  const overflow = useRef(null);
  const { auth } = useContext(AuthContext);
  const scrollY = useWindowScroll();

  const wheel = featureFlags.isOn("wheel-of-fortune");

  const links = [
    {
      to: "/",
      title: "Home",
      icon: "home",
    },
    {
      to: "/tournaments",
      title: "Tournaments",
      icon: "prizes",
      need_auth: true,
    },
    {
      to: "/backpack",
      title: "My Backpack",
      icon: "backpack",
      need_auth: true,
    },
    {
      to: "/marketplace",
      title: "Marketplace",
      icon: "shopping",
    },
    {
      to: "/loot-packs",
      title: "Loot Packs",
      icon: "lootboxes",
      need_auth: true,
    },
    ...(wheel
      ? [
          {
            to: "/wheel-of-fortune",
            title: "Wheel Of Fortune ",
            icon: "wheel",
            need_auth: true,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (active) stopScroll(scrollY);
    return () => startScroll();
  }, [active]);

  useEffect(() => {
    overflow && isOpen && overflow.current.scrollTo(0, 0);
    if (isOpen) stopScroll(scrollY);
    return () => startScroll();
  }, [isOpen]);

  const show = () => {
    !isMobile && setActive(true);
  };
  const hide = () => {
    !isMobile && setActive(false);
  };

  return (
    <aside className={active || isOpen ? "active" : ""}>
      {isMobile ? (
        <div className="top df jc-sb">
          <FunticoLogo />

          <button
            className="close_mob close_icon"
            onClick={(e) => openNav(false)}
          ></button>
        </div>
      ) : null}

      <div className="overflow df fd-c " onMouseLeave={hide} ref={overflow}>
        <nav className="nav df fd-c " onMouseEnter={show}>
          <div className="links df fd-c br-16">
            {links.map(({ icon, to, ...link }) => (
              <DelayLink
                key={to}
                to={to}
                {...link}
                className="link df ai-c br-14"
              >
                <div className={`ic icon_${icon}`}></div>
                <div className="txt">{link.title}</div>
              </DelayLink>
            ))}
          </div>

          {isMobile ? <UnLoginedButton state={state} auth={auth} /> : null}
          {auth?.token ? <RecentlyGames mobile={isMobile} /> : null}

          <div className="drid df fd-c">
            <div className="column pages df fd-c">
              {discover.map(({ icon, ...link }) => (
                <DelayLink key={link.to} {...link} className="link">
                  <div
                    className="txt"
                    dangerouslySetInnerHTML={{
                      __html: link.title,
                    }}
                  />
                </DelayLink>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </aside>
  );
}
