import React, { useEffect, Suspense, useContext, useState, lazy } from "react";
import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DataContext from "./context/DataProvider";
import Layout from "./layouts/Layout";
import Preloader from "./components/layout/Preloader";
import getUserData from "./components/core/GetUserData";
import AuthContext from "./context/AuthProvider";
import "./assets/scss/index.scss";
import useAxiosREST from "./utils/hooks/useAxiosAPI";
import { checkSearchParams } from "./utils/utils";
import ErrorBoundary from "./ErrorBoundary";

import { gsap, ScrollTrigger, Observer, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin);

Object.assign(window, {
  gsap, ScrollTrigger, Observer, ScrollToPlugin
});

const Lucky = lazy(() => import('./views/lucky/Lucky'));


export default function App({ state }) {
  const { data } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const [routes, setRoutes] = useState();
  const { pathname } = useLocation();

  const params = useSearchParams();
  const navigate = useNavigate();

  const fetch = useAxiosREST();
  const { getUser } = getUserData();

  const runSearchParamsHooks = async () => {
    await checkSearchParams(
      params,
      fetch,
      state,
      auth,
      pathname,
      navigate,
      getUser
    );
  };

  useEffect(() => {
    runSearchParamsHooks();
    return () => {};
  }, []);

  useEffect(() => {
    if (data && data.routes) {
      const createRoute = ({ component: Component, children, ...e }) => {
        return (
          <Route
            key={e.path}
            {...e}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ErrorBoundary fallback={<div>An Error occurred</div>}>
                  <Component state={state} />
                </ErrorBoundary>
              </Suspense>
            }
          >
            {children?.map(createRoute)}
          </Route>
        );
      };

      const routes = data.routes.map(createRoute);

      setRoutes(routes);
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    if (auth?.token && !auth?.wallet) getUser();
    return () => {};
  }, [auth.token]);

  return (
    <>
      {<Preloader state={state} />}
      {data && routes ? (
        <>
          <Routes>
            <Route path="/" element={<Layout state={state} auth={auth} />}>
              {routes}
            </Route>
            <Route path="/lucky-funatic" element={<Lucky state={state} />} />
          </Routes>
        </>
      ) : null}
    </>
  );
}
