import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import Login from "/components/pop-ups/Login";
import Registration from "./Registration";
import { ResetPassword, CreatePassword } from "./Password";
import Verify from "/components/pop-ups/Verify";
import Write from "/components/pop-ups/WriteUs";
import Confirm from "/components/pop-ups/Confirm";
import Success from "/components/pop-ups/Success";
import Table from "/components/pop-ups/Table";
import { startScroll, stopScroll } from "/utils/scroll-config";
import { useWindowScroll } from "/utils/hooks/use-EventListener";
import Play from "/components/pop-ups/PlayGame";
import Data from "/components/pop-ups/CustomData";

gsap.config({ nullTargetWarn: false });
const ESCAPE_KEYS = ["27", "Escape"];

const PopUps = ({ state }) => {
  const opened = state.getState().opened_popup;
  const [activePart, setActivePart] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const scrollContent = useRef(null);
  const scrollY = useWindowScroll();

  const obj = {
    state: state,
    data: activePart,
  };
  const parts = {
    login: <Login {...obj} />, //done
    reg: <Registration {...obj} />, //done
    reset: <ResetPassword {...obj} />, //done
    create: <CreatePassword {...obj} />,
    verify: <Verify {...obj} />,
    write: <Write {...obj} />, //done
    confirm: <Confirm {...obj} />,
    success: <Success {...obj} />, //done
    table: <Table {...obj} />,
    game: <Play {...obj} />,
    data: <Data {...obj} />,
  };

  const keyHandler = ({ key }) => {
    ESCAPE_KEYS.includes(String(key)) && state.setPopUp("");
  };

  const closeOnClick = ({ target }) => {
    if (target.dataset.close) {
      state.setPopUp("");
    }
  };

  const openPopUp = () => {
    scrollContent?.current?.scrollTo(0, 0);
    setIsShow(true);
    setActivePart(opened);
    stopScroll(scrollY);
    document.addEventListener("keydown", keyHandler, false);
    document.addEventListener("click", closeOnClick, false);
  };

  useEffect(() => {
    gsap.to([], {
      duration: activePart?.open ? 0.3 : 0,
      onComplete: (e) => (opened.open ? openPopUp() : setActivePart(null)),
    });

    return () => {
      setIsShow(false);
      startScroll(scrollY);
      document.removeEventListener("keydown", keyHandler, false);
      document.removeEventListener("click", closeOnClick, false);
    };
  }, [opened.open]);

  return (
    <>
      <div className={`pop_up ${isShow ? "active" : ""}`} data-close="true">
        <div
          className="overflow df jc-c ai-st"
          ref={scrollContent}
          data-close="true"
        >
          <div
            className={`content df fd-c ${activePart?.open ?? ""} ${
              activePart?.classname ?? ""
            }`}
          >
            {parts[activePart?.open]}

            <button className="df close close_icon" data-close="true"></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopUps;
