import React from "react";

import CustomButton from "/components/core/ButtonDefault";
import { payToLocaleString } from "/utils/utils";
import useSWR, { useSWRConfig } from "swr";
import { BigDecimal } from "./money";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import useSWRMutation from "swr/mutation";

/**
 * 1. This screen should provide full details about the withdrawal without any hidden details.
 * 2. The fields should be listed in "math" opreration order
 * @param {{
 *  changePart: (path: string, queryParams: import("./queryParams").WithdrawalQueryParams) => void,
 *  isUserKYC, changeTab, amountState, addressState, onConfirm, data: import("./queryParams").WithdrawalQueryParams }} props
 * @returns
 */
export default function Confirm({
  changePart,
  data: { amountValue, addressValue, chain },
  onConfirm,
}) {
  const axiosClient = useAxiosWithAuth();
  const balance = useSWR(
    () => ["cashier/balance"],
    ([url]) => axiosClient.wrap(axiosClient.get(url)),
    {
      refreshInterval: 25000,
      keepPreviousData: true,
    }
  );

  const pay = useSWRMutation(
    [`cashier/withdrawal/create-withdrawal-request`],
    ([url], { arg: { amount, address, chainId } }) =>
      axiosClient.wrap(
        axiosClient.post(url, {
          amount,
          address,
          chainId,
        })
      ),
    {
      onSuccess: (data, key, config) => {
        console.log("withdrawal onSuccess", data, key, config);
      },
    }
  );

  const new_balance = () =>
    balance.data && balance.data?.withdrawableBalance && amountValue
      ? new BigDecimal(balance.data?.withdrawableBalance).minus(
          new BigDecimal(amountValue)
        )
      : new BigDecimal(0);

  return (
    <div className="withdraw-confirm df fd-c" data-hide-top>
      <h2 className="fixel_26 text-center">Withdraw $TICO</h2>
      <div className="tico-information df fd-c gap-16">
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Withdrawing To</p>
          <p className="fixel_16_sbl">{addressValue}</p>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Amount To Withdraw</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">{payToLocaleString(amountValue)}</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Service Fee (1%)</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">20</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Available To Withdraw </p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">{balance.data?.withdrawableBalance}</p>
          </div>
        </div>
        <div className="full-line"></div>

        <div className="row df jc-sb ai-c gap-10 fixel_22">
          <p>Balance After Withdrawal</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p>{payToLocaleString(new_balance().toFixed(0))}</p>
          </div>
        </div>
      </div>

      {pay.error && pay.error.message}

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={() => changePart("flow", { amountValue, addressValue })}
          classname={`grey`}
          def={40}
          title={"Back"}
        />
        <CustomButton
          onClick={async () => {
            await pay.trigger({
              amount: amountValue,
              address: addressValue,
              chainId: chain.chainId,
            });

            onConfirm();
          }}
          // todo: implement loading
          // loading={pay.isMutating}
          disabled={(new_balance().lt(0) && pay.isMutating) || pay.error}
          classname={`pr`}
          def={40}
          title={"Confirm Withdrawal"}
        />
      </div>
    </div>
  );
}
